<template>
  <div class="">
    <span>交流讨论></span>
    <span>快运分会></span>
    <span>消息详情</span>
    <h1>快运分会</h1>
    <p @click="addMess" class="add">+添加回复</p>
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <p>
          <span>{{ item.content }}</span
          ><span>{{ item.createDate }}</span>
        </p>
        <p>{{}}</p>
      </li>
    </ul>

    <el-dialog
      title="回复"
      :visible="addDialogshow"
      width="900px"
      :close-on-click-modal="false"
      :show-close="true"
      :before-close="clearSubmit"
    >
      <el-input
        type="text"
        v-model="content"
        placeholder="请输入您要回复的内容"
      />
      <span slot="footer">
        <el-button plain @click="clearSubmit">取消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click.native.prevent="addDialogSubmit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { IndexStore } from "@/store/module/index";

import { CListDiscussion, DiscussionAdd } from "@/http/api/InitiationApi";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      userInfo: IndexStore.userInfo,
      list: [],
      addDialogshow: false,
      content: "",
      id: this.$route.query.id,
      nickName: this.$route.query.nickName,
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    addMess() {
      this.addDialogshow = true;
    },
    getlist() {
      CListDiscussion({
        id: this.id,
      }).then((res) => {
        this.list = res.result;
      });
    },
    addDialogSubmit() {
      DiscussionAdd({
        content: this.content,
        receiveUserId: this.id,
      }).then((res) => {
        this.getlist();
        this.content = "";
        this.addDialogshow = false;
      });
    },
    clearSubmit() {
      this.addDialogshow = false;
    },
    getUserInfoFun() {
      const that = this;
      if (!IndexStore.userInfo) {
        setTimeout(() => {
          that.getUserInfoFun();
        }, 1000);
      } else {
        this.userInfo = IndexStore.userInfo;
      }
    },
  },
};
</script>
<style  scoped>
ul li {
  width: 90%;
  height: 50px;
  line-height: 50px;
  border: 1px solid #ccc;
  background-color: #eee;
  margin: 10px;
  display: flex;
  justify-content: space-between;
}
ul li p span {
  width: 35%;
  text-align: center;
}
.add {
  width: 100%;
  text-align: center;
  width: 90%;
  height: 50px;
  line-height: 50px;
  border: 1px solid #ccc;
  background-color: #eee;
  margin: 10px;
  display: flex;
  justify-content: center;
}
</style>